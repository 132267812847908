<template>
  <div id="featurePanelBody">
    <v-tooltip
      v-model="showToolTip"
      :open-on-hover="false"
      bottom
      :disabled="featureAttribute.value === null || isShowTooltip"
    >
      <template v-slot:activator="{}">
        <div>
          <v-list
            dense
            v-for="(value, key) in currentFeatureInfo.featureAttributes
              .attributes"
            :key="key"
          >
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content
                v-if="!currentFeatureInfo.featureAttributes.currentEditfeatures"
                v-html="key"
                @click="showDetailsInDialog({ key: key, value: value })"
                class="ellipsis"
              ></v-list-item-content>
              <v-list-item-content
                class="align-end ellipsis"
                @click="showDetailsInDialog({ key: key, value: value })"
                v-html="value"
              ></v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </template>
      <span>Value Copied</span>
    </v-tooltip>
    <v-list
      dense
      v-for="(join, index) in currentFeatureInfo.featureAttributes.joins"
      :key="index"
    >
      <v-divider></v-divider>
      <v-list-item class="no-right-padding">
        <v-list-item-content
          v-html="join.key"
          class="ellipsis"
          @click="showDetailsInDialog({ key: join.key, joinValue: join.count })"
        ></v-list-item-content>
        <v-list-item-content
          class="align-end"
          v-html="join.count"
        ></v-list-item-content>
        <v-list-item-action>
          <v-btn icon @click="selectJoinFeature(join.key, join.value)">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import { mapState } from "vuex";
import featuresApi from "@/api/features";

export default {
  name: "featureSlider",
  data() {
    return {
      showToolTip: false
    };
  },
  computed: mapState({
    currentFeatureInfo: state => state.featurePanel.currentFeatureInfo,
    featureAttribute: state => state.featurePanel.featureAttribute,
    homeConfig: state => state.home.config,
    isShowTooltip: state => !state.setting.isToolTipsOn
  }),
  methods: {
    showDetailsInDialog(attribute) {
      if (this.currentFeatureInfo.featureAttributes.currentEditfeatures) {
        this.$store.commit(
          "edit/changeSelectedFeature",
          this.currentFeatureInfo.featureAttributes.currentEditfeatures[
            attribute.key
          ]
        );
        this.currentFeatureInfo.featureAttributes.currentEditfeatures[
          attribute.key
        ].clonedElement.style.zIndex = "9999";
        if (
          this.currentFeatureInfo.featureAttributes.featureType[
            attribute.key
          ] === "LatLonGORedlinePhoto"
        ) {
          this.currentFeatureInfo.featureAttributes.currentEditfeatures[
            attribute.key
          ].clonedElement.firstElementChild.style.fill = this.homeConfig.highlight_fill_color;
          this.$store.commit("commitAppMode", "editsPhotoDialog");
        } else {
          this.currentFeatureInfo.featureAttributes.currentEditfeatures[
            attribute.key
          ].clonedElement.firstElementChild.childNodes.forEach(
            node => (node.style.fill = this.homeConfig.highlight_fill_color)
          );
          this.$store.commit("commitAppMode", "audioDialog");
        }
      } else {
        this.$store.commit("featurePanel/changeSelectedAttribute", attribute);
        if (!attribute.joinValue) {
          this.$copyText(this.featureAttribute.value);
          this.showToolTip = true;
          setTimeout(() => {
            this.showToolTip = false;
          }, 1000);
        }
      }
    },
    selectJoinFeature(title, joinFeaturesMetadata) {
      let currentFeature = this.currentFeatureInfo.feature;
      let currentFeatureInfo = {};
      // Fetching the join features by their ids if the joint feature is not type feature
      // (expecting it would be of type relationship) else will show up the feature details
      if (
        joinFeaturesMetadata.type &&
        joinFeaturesMetadata.type !== "Feature"
      ) {
        featuresApi
          .fetchJoinFeature(currentFeature, joinFeaturesMetadata, title)
          .then(joinFeatures => {
            // If there is only one join feature then directly displaying the feature details else
            // displaying the list of join feature's name and id
            if (joinFeatures.length == 1) {
              let joinFeatureMetadata = joinFeaturesMetadata.features[0];
              let joinFeature = joinFeatures[0];
              let otherFeatures = [currentFeature];
              currentFeatureInfo = {
                isFeatureList: false,
                layerName: joinFeature.layername,
                feature: joinFeature,
                hasOtherFeatures: true,
                title: title,
                back: {
                  title: this.currentFeatureInfo.title,
                  features: otherFeatures
                },
                searchType: "Feature"
              };
              currentFeature = joinFeature;
              currentFeature.title = title;
            } else {
              let otherFeatures = [currentFeature];
              currentFeatureInfo = {
                isFeatureList: true,
                feature: joinFeatures,
                hasOtherFeatures: true,
                title: title,
                back: {
                  title: this.currentFeatureInfo.title,
                  features: otherFeatures
                },
                searchType: "Feature"
              };
              currentFeature = joinFeatures;
            }
            //adding the isMultiplePins in back details
            if (this.currentFeatureInfo.showMultiplePins) {
              currentFeatureInfo.back[
                "showMultiplePins"
              ] = this.currentFeatureInfo.showMultiplePins;
            }

            this.$store.dispatch(
              "featurePanel/handleShowJoinFeature",
              currentFeatureInfo
            );
            this.$emit("handleTransition");
          });
      } else {
        let currentFeatureInfo = {
          isFeatureList: false,
          feature: joinFeaturesMetadata,
          hasOtherFeatures: true,
          title: title,
          back: {
            title: this.currentFeatureInfo.title,
            features: this.currentFeatureInfo.feature
          }
        };
        if (
          joinFeaturesMetadata.searchType &&
          joinFeaturesMetadata.searchType === "editFeature"
        ) {
          currentFeatureInfo["searchType"] = "editFeature";
          currentFeatureInfo["feature"] = joinFeaturesMetadata.feature;
        }
        //adding the isMultiplePins in back details
        if (this.currentFeatureInfo.showMultiplePins) {
          currentFeatureInfo.back[
            "showMultiplePins"
          ] = this.currentFeatureInfo.showMultiplePins;
        }
        this.$store.dispatch(
          "featurePanel/handleShowJoinFeature",
          currentFeatureInfo
        );
        this.$emit("handleTransition");
      }
    }
  }
};
</script>
<style scoped>
#featurePanelBody {
  font-size: 14px;
}
#featurePanelBody .v-list-item__action {
  margin: 0px !important;
}
#featurePanelBody .v-list {
  padding: 0px !important;
}
.align-end {
  flex-flow: column-reverse;
}
.no-right-padding {
  padding-right: 0px !important;
}
.ellipsis {
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}
</style>
