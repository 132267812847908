<template>
  <v-card class="mx-auto" max-width="400">
    <v-toolbar class="backgroundColor">
      <v-tooltip
        :disabled="isShowTooltip"
        bottom
        color="white"
        v-if="isBackVisible"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            text
            id="backButtonStyle"
            @click="showBackFeature()"
            v-on="on"
            color="primary"
          >
            <v-icon>mdi-chevron-left</v-icon>
            <span class="backButtontext">{{ backButtonText }}</span>
          </v-btn>
        </template>
        <span style="color:black">{{ backButtonText }}</span>
      </v-tooltip>
      <v-spacer v-if="!isBackVisible"></v-spacer>
      <v-toolbar-title id="toolbarTitle"
        ><b>{{ panelTitle }}</b></v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-tooltip :disabled="isShowTooltip" bottom color="white">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" v-on="on" icon @click="closePanel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <span style="color:black">{{ $t("Close") }}</span>
      </v-tooltip>
    </v-toolbar>

    <vue-perfect-scrollbar
      class="chat-room--scrollbar"
      v-bind:style="{ 'min-height': '1vh' }"
      ref="vuePerfectScroll"
    >
      <transition :name="slide" v-on:after-leave="resetScroll">
        <featurePanelSlider
          class="featureSlideView"
          @handleTransition="handleTransition"
          v-if="show"
        ></featurePanelSlider>
      </transition>
      <transition :name="slide" v-on:after-leave="resetScroll">
        <featurePanelSlider
          class="featureSlideView"
          @handleTransition="handleTransition"
          v-if="!show"
        ></featurePanelSlider>
      </transition>
    </vue-perfect-scrollbar>

    <v-divider></v-divider>

    <v-card-actions
      v-if="isFeatureShareOn && panelTitle === panelRootTitle"
      class="backgroundColor"
      id="iconRight"
    >
      <v-tooltip :disabled="isShowTooltip" bottom color="white">
        <template v-slot:activator="{ on }">
          <v-btn left color="primary" icon @click="sendFeatureData()" v-on="on"
            ><v-icon>mdi-open-in-new</v-icon></v-btn
          >
        </template>
        <span style="color:black">{{ $t("Share") }}</span>
      </v-tooltip>
    </v-card-actions>
    <v-card-actions
      v-if="
        panelTitle !== panelRootTitle &&
          currentFeatureInfo.type !== 'editFeature' &&
          currentFeatureInfo.type !== 'multiEditFeatures'
      "
      class="backgroundColor"
    >
      <v-tooltip :disabled="isShowTooltip" bottom color="white">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" icon @click="goToRouting()" v-on="on"
            ><v-icon>mdi-car</v-icon></v-btn
          >
        </template>
        <span style="color:black">{{ $t("Routing") }}</span>
      </v-tooltip>
      <v-tooltip
        :disabled="isShowTooltip"
        bottom
        color="white"
        v-if="isInternalsAvailable"
      >
        <template v-slot:activator="{ on }">
          <v-btn id="internalsSchematicsIcon" icon v-on="on">
            <v-img max-height="18" max-width="18" src="@/assets/internal.svg" />
          </v-btn>
        </template>
        <span style="color:black">{{ $t("Internals") }}</span>
      </v-tooltip>
      <v-tooltip
        :disabled="isShowTooltip"
        bottom
        color="white"
        v-if="isSchematicsAvailable"
      >
        <template v-slot:activator="{ on }">
          <v-btn id="internalsSchematicsIcon" icon v-on="on">
            <v-img
              max-height="18"
              max-width="18"
              src="@/assets/schematics.svg"
            />
          </v-btn>
        </template>
        <span style="color:black">{{ $t("Schematics") }}</span>
      </v-tooltip>
      <v-spacer></v-spacer>

      <!-- Featurelinks -->

      <v-tooltip
        :disabled="isShowTooltip"
        bottom
        color="white"
        v-if="featureLinks.length === 1"
      >
        <template v-slot:activator="{ on }">
          <v-btn color="primary" text v-on="on" @click="openFeatureLink(0)"
            ><v-icon>mdi-link</v-icon>{{ featureLinks[0].label }}</v-btn
          >
        </template>
        <span style="color:black">Featurelinks</span>
      </v-tooltip>

      <div class="text-center" v-if="featureLinks.length > 1">
        <v-menu offset-y>
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip :disabled="isShowTooltip" bottom color="white">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  color="primary"
                  icon
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <span style="color:black">{{ $t("Featurelinks") }}</span>
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item
              v-for="(link, index) in featureLinks"
              :key="index"
              link
              @click="openFeatureLink(index)"
            >
              <v-list-item-icon><v-icon>mdi-link</v-icon></v-list-item-icon>
              <v-list-item-title v-text="link.label"></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-card-actions>
  </v-card>
</template>
<script>
import featuresApi from "@/api/features";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapGetters, mapState } from "vuex";
import featurePanelSlider from "@/components/FeatureSlider";

export default {
  name: "featurePanel",
  components: {
    VuePerfectScrollbar,
    featurePanelSlider
  },
  data() {
    return {
      show: true,
      slide: "toLeft",
      panelRootTitle: "Features"
    };
  },
  computed: {
    ...mapState({
      panelTitle: state => state.featurePanel.panelTitle,
      isFeatureShareOn: state => state.setting.isShareFeatureOn,
      isBackVisible: state => state.featurePanel.isBackVisible,
      currentFeatureInfo: state => state.featurePanel.currentFeatureInfo,
      backButtonText: state => state.featurePanel.backButtonText,
      isInternalsAvailable: state =>
        state.featurePanel.currentFeatureInfo.feature &&
        state.featurePanel.currentFeatureInfo.feature.internals > 0,
      isSchematicsAvailable: state =>
        state.featurePanel.currentFeatureInfo.feature &&
        state.featurePanel.currentFeatureInfo.feature.schematics > 0,
      featureDetails: state => state.featurePanel.featureDetails,
      isShowTooltip: state => !state.setting.isToolTipsOn
    }),
    ...mapGetters({
      featureLinks: "featurePanel/featureLinks"
    })
  },
  methods: {
    closePanel() {
      this.$store.dispatch("handleBack");
    },
    showBackFeature() {
      if ( this.backButtonText === this.panelRootTitle &&
        this.featureDetails.length > 0) {
        this.$store.dispatch("handleSwitchFromHome", {
          mode: "featurePanel",
          value: {
            searchType: "Feature",
            feature: this.featureDetails,
            title: this.panelRootTitle
          }
        });
      } else {
        this.$store.dispatch("featurePanel/handleShowPreviousFeature");
      }
      this.slide = "toRight";
      this.toggleView();
    },
    handleTransition() {
      this.slide = "toLeft";
      this.toggleView();
    },
    toggleView() {
      setTimeout(() => {
        this.show = !this.show;
      }, 0);
    },
    resetScroll() {
      let scrollBar = this.$refs.vuePerfectScroll;
      scrollBar.ps.scrollbarX.hidden = true;
      scrollBar.$el.scrollTop = 5;
      scrollBar.ps.update();
    },
    goToRouting() {
      // Prepration on routing level.
      this.$insProgress.start();
      let destination = [
        this.currentFeatureInfo.geometry.coordinates[1],
        this.currentFeatureInfo.geometry.coordinates[0]
      ];
      this.$store.dispatch("routing/handleRouteToDestination", destination);
    },
    openFeatureLink(index) {
      let webFeatureLink = this.featureLinks[index].url_web;
      let currentFeature = this.currentFeatureInfo.feature;

      //This is for google street view
      let googleStreetViewPlaceholder = "{center},{center}";
      webFeatureLink = webFeatureLink.replace(
        googleStreetViewPlaceholder,
        currentFeature.anchor[1] + "," + currentFeature.anchor[0]
      );

      //This is for keys from properties or other keys from feature
      let placeholders = webFeatureLink.match(/\{.*?\}/g);
      if (placeholders !== null) {
        let placeholdersString = placeholders.map(x => x.replace(/[{}]/g, ""));
        placeholdersString.forEach(placeholder => {
          if (placeholder === "anchor") {
            webFeatureLink = webFeatureLink.replace(
              "{" + placeholder + "}",
              currentFeature.anchor[1] + "," + currentFeature.anchor[0]
            );
          } else {
            webFeatureLink = webFeatureLink.replace(
              "{" + placeholder + "}",
              currentFeature.properties[placeholder] ||
                currentFeature[placeholder]
            );
          }
        });
      }
      if (webFeatureLink.indexOf("postmessage") !== -1) {
        let featureAttr = webFeatureLink.split("?");
        window.top.postMessage(featureAttr[1], "*");
      } else {
        webFeatureLink = webFeatureLink.match(/^http[s]?:\/\//)
          ? webFeatureLink
          : "http://" + webFeatureLink;
        window.open(webFeatureLink);
      }
    },
    sendFeatureData() {
      window.top.postMessage(this.currentFeatureInfo.feature, "*");
    }
  }
};
</script>
<style scoped>
/* .ps-container{
  max-height: calc(100vh - 500px) !important;
} */
.ps-container {
  height: 250px;
}
.backButtontext {
  width: 75px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
  font-weight: bold;
  text-align: left;
}
#toolbarTitle {
  font-size: 1rem;
  min-width: 33%;
  text-align: center;
}
#backButtonStyle {
  left: -15px;
  padding: 0px;
}
.toRight-leave-active,
.toRight-enter-active,
.toLeft-leave-active,
.toLeft-enter-active {
  transition: 0.5s linear all;
}
.toRight-enter {
  transform: translate(-100%, 0);
}
.toRight-leave-to {
  transform: translate(100%, 0);
}
.toLeft-enter {
  transform: translate(100%, 0);
}
.toLeft-leave-to {
  transform: translate(-100%, 0);
}
#internalsSchematicsIcon {
  margin-left: 10px !important;
}
.backgroundColor {
  background-color: rgba(242, 242, 242, 0.5) !important;
}
#iconRight {
  padding-left: 280px;
}
</style>
